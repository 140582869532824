













































































































































































































































import { Component, Vue } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import {
    apiOtherResources,
    apiServiceFeeStatus,
    apiSetRates,
    getResourcesLogApi,
    selectFeaturedResourcesApi,
} from "@/api/goods";
import { RequestPaging } from "@/utils/util";
import setMoviecityPrice from "@/components/set-moviecity-price.vue";
import SetResourcesPrice from "@/views/goods_center/other_resources/set-resources-price.vue";
import ResourcesDetails from "@/views/goods_center/other_resources/resources-details.vue";
import ResourcesLog from "@/views/goods_center/other_resources/resources-log.vue";
import shopTransfer from "@/components/transfer.vue";
import { apiShopLists } from "@/api/shop";
import { deepClone } from "@/utils/util.ts";

@Component({
    components: {
        ResourcesDetails,
        ResourcesLog,
        SetResourcesPrice,
        setMoviecityPrice,
        LsDialog,
        MaterialSelect,
        lsPagination,
        shopTransfer,
    },
})
export default class OperationManagementIndex extends Vue {
    /** S Data **/
    $refs!: { priceForm: any };

    identity: number | null = null; // 当前编辑用户的身份ID
    apiGoodsPackgeLists = apiOtherResources;
    pager: RequestPaging = new RequestPaging();
    priceForm: any = {
        price_list: {
            price_type: "1",
            price_ratio: "1",
            price_add_sub: "1",
            price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: 2,
        shop_price_type: "",
    };
    dialogPrice: boolean = false;
    show_details: boolean = false;
    build_form: object = {
        service_fee_status: "0", //运营包名称
        resource_name: "", //运营包名称
    };
    listsNopage = [];
    log_list = [];
    log_item: Object = {};
    show_log = false;
    searchObj: Object = {
        service_fee_status: "0",
        resource_name: "",
    };
    this_item: Object = { id: "" };
    tmp_row: Object = {
        service_percentage: "",
        price: "",
    };
    pane_label: Object = {
        alreadySet: "0",
        notSet: "0",
    };

    //挑选
    dialog_title: string = "";
    shop_loading: boolean = false;
    price_loading: boolean = false;
    dialogShop: boolean = false;
    is_pick: boolean = false;
    shop_list: any = [];

    pickForm: any = {
        id: "",
        shop_data: [],
    };

    showDetails(item: Object): void {
        this.this_item = item;
        this.show_details = true;
    }

    showLog(item: any): void {
        this.log_item = item;
        console.log(this.log_item, "this.log_item");
        this.show_log = true;
    }

    async setServiceFee() {
        let data: any = {};
        data = this.is_pick
            ? deepClone({ ...this.priceForm, ...this.pickForm })
            : deepClone(this.priceForm);
        this.price_loading = true;
        try {
            this.is_pick
                ? await selectFeaturedResourcesApi(data)
                : await apiSetRates(data);
            this.$message({
                type: "success",
                message: this.is_pick ? "挑选成功" : "改价成功",
            });
            this.price_loading = false;
            this.dialogPrice = false;

            this.getServiceFeeStatus();
            this.getGoodsPackgeLists();
        } catch (error) {
            this.price_loading = false;
        }
    }

    /** E Data **/
    async modifyServiceFee(row: any): Promise<void> {
        this.dialogPrice = true;
        this.is_pick = false;
        this.tmp_row = JSON.parse(JSON.stringify(row));
        this.priceForm.id = this.tmp_row.id;
        this.priceForm.price_list = this.tmp_row;
        this.priceForm.price_rounding = this.tmp_row.price_rounding;
        this.priceForm.reserve_decimal = this.tmp_row.reserve_decimal;
    }

    // 获取运营包
    getGoodsPackgeLists(page?: number,): void {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiOtherResources,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 获取运营包
    handleConfirmPrice(): void {}

    // 获取运营包
    async getServiceFeeStatus(): Promise<void> {
        this.pane_label = await apiServiceFeeStatus({});
        console.log(this.pane_label);
    }

    handleClick() {
        this.getGoodsPackgeLists();
    }

    // 挑选
    handPick(item: any) {
        this.dialog_title = `挑选加入商城（已选中${item.resource_name}）`;
        this.is_pick = true;
        this.dialogShop = true;
        this.shop_loading = true;
        this.pickForm = {
            id: item.id,
            shop_data: [],
        };
        this.priceForm = {
            price_list: {
                price_type: 1,
                price_ratio: 1,
                price_add_sub: "1",
                price_number: 1,
            },
            price_rounding: 1,
            reserve_decimal: 2,
        };
        apiShopLists({
            page_type: 0,
            app_id: 10,
        }).then((res) => {
            this.shop_list = res.lists;
            this.shop_loading = false;
        });
    }
    async handleConfirmPick() {
        if (this.pickForm.shop_data.length === 0) {
            this.$message.warning("请选择商城");
            return;
        }
        this.dialog_title = "修改服务费";
        this.dialogShop = false;
        this.dialogPrice = true;
    }

    // 返回选商城
    resetShop() {
        this.dialogPrice = false;
        this.dialogShop = true;
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            if (key != "service_fee_status") {
                this.$set(this.searchObj, key, "");
            }
        });
        this.getGoodsPackgeLists();
    }

    created() {
        this.getServiceFeeStatus();
        this.getGoodsPackgeLists();
    }
}
