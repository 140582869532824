import { render, staticRenderFns } from "./set-resources-price.vue?vue&type=template&id=436e7c74&scoped=true&"
import script from "./set-resources-price.vue?vue&type=script&lang=ts&"
export * from "./set-resources-price.vue?vue&type=script&lang=ts&"
import style0 from "./set-resources-price.vue?vue&type=style&index=0&id=436e7c74&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436e7c74",
  null
  
)

export default component.exports