



































































import { Component, Vue, Prop } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import {
  apiOtherResources,
  apiServiceFeeStatus,
  apiSetRates,
  otherResourcesShopListsApi,
} from "@/api/goods";
import { copyObject, RequestPaging } from "@/utils/util";
import setMoviecityPrice from "@/components/set-moviecity-price.vue";
import SetResourcesPrice from "@/views/goods_center/other_resources/set-resources-price.vue";
import SettlementEditor from "@/views/finance/editor/settlementEditor.vue";
@Component({
  components: {
    SetResourcesPrice,
    setMoviecityPrice,
    LsDialog,
    MaterialSelect,
    lsPagination,
  },
  watch: {
    id(value) {
      let that = <ResourcesDetails>this;
      that.getGoodsPackgeLists();
    },
  },
})
export default class ResourcesDetails extends Vue {
  /** S Data **/
  pager: RequestPaging = new RequestPaging();
  searchObj: Object = {
    id: "",
  };
  @Prop(Number) id!: number; // 声明接收父组件传递的ID
  @Prop(Number) details!: Object; // 声明接收父组件传递的ID
  // 获取运营包
  getGoodsPackgeLists(page?: number) {
    page && (this.pager.page = page);
    this.searchObj.id = this.id;
    this.pager.request({
      callback: otherResourcesShopListsApi,
      params: {
        ...this.searchObj,
      },
    });
  }

  created() {
    this.getGoodsPackgeLists();
  }
}
